<template>
    <div class="selectRole">

        <div class="selectRole_top">
            <div class="selectRole_title">篱笆匠人</div>
            <div class="selectRole_points">
                <img src="assets/points.png" />
            </div>
            <div class="selectRole_white"></div>
            <div class="selectRole_blue"></div>
        </div>
        <div class="selectRole_content">
            <div class="selectRole_tips">
                欢迎 <span style="color: orange">{{nickName}}</span> ,请选择您的角色
            </div>
            <div class="selectRole_radio">
                <Radio v-model="radioValue" :options="options" @change="check" class="radioStyle" />
            </div>
            <div class="selectRole_btn" v-if="openId==''" @click="confirm">确定</div>
            <div class="selectRole_btn selectRole_btn_wxapp" v-else >
                <wx-open-launch-weapp
                        id="launch-btn"
                        username="gh_054673fe64e5"
                        :path="'/pages/index/index.html?opid='+openId"
                        env-version="trial"
                        @launch="weLaunch"
                        style="position:absolute ;  width: 100% ; height: 100%;top: 0; left: 0;display: flex;flex-direction:column; justify-content: center;"
                >
                    <script type="text/wxtag-template">
                        <style>
                            .bindphone{
                                color: white;
                                letter-spacing: 2px;
                                font-size: 18px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                            }
                        </style>
                        <div class="bindphone"> 确定,去绑定手机号 </div>
                    </script>
                </wx-open-launch-weapp>
            </div>
            <div class="selectRole_xy">
                <Checklist v-model="chkValue" :options="chkOptions" @change="checkChange" class="chkStyle" />
                <div>我已阅读并同意使用 <span style="color:red" @click="toproxy">服务协议</span> </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Radio,Checklist,Toast} from 'mint-ui';
    import keys from "../xyg/utils/keys";
    import wx from 'weixin-js-sdk';
    import request from "../xyg/utils/request";

    const weixin={

        config(){
            let ticket_url="/api/common/getWxJsApiTicket";
            let domain=keys.webSite+'/selectrole';//http://h5.xingship.com/'; //'http://h5.xingship.com/selectrole' ;//
            let data={url:domain};

            request.post(ticket_url,data)
                .then(rsp=>{
                    if(rsp.code==200 && rsp.success){
                        try{
                            let rspData=rsp.data;
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: rspData.appId, // 必填，公众号的唯一标识
                                timestamp: rspData.timesTamp, // 必填，生成签名的时间戳
                                nonceStr: rspData.nonceStr, // 必填，生成签名的随机串
                                signature: rspData.signature,// 必填，签名
                                jsApiList: ['previewImage'], // 必填，需要使用的JS接口列表
                                openTagList:['wx-open-launch-weapp']
                            });
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                })
                .catch(e=>{
                    console.log(e);
                })
        },

        error(){
            wx.error(function (res) {
                console.log(res);
            })
        },

        ready(){
            wx.ready(function () {
                console.log('wx config ready...');
            });
        },

        init(){
            this.config();
            this.ready();
            this.error();
        }

    };

    export default {
        name: "SelectRole",
        components:{
            Radio,
            Checklist
        },
        data(){
            return{
                openId:'',
                nickName:'',
                radioValue:'',
                chkValue:[],
                options:[{
                    label:'企业',
                    value:"1",
                },{
                    label:'技师',
                    value:"0",
                }],
                chkOptions:[{
                    label:' ',
                    value:'ok',
                }]
            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                this.openId=localStorage.getItem(keys.openId);
                this.nickName=localStorage.getItem(keys.nickName);
                weixin.init();
            },
            check:function () {
                console.log(this.radioValue);
            },
            checkChange:function () {
                console.log(this.chkValue[0]);
            },
            confirm(){
                let openId=localStorage.getItem(keys.openId);
                if(this.radioValue==""){
                    Toast({
                        message: '请选择您的角色',
                        position: 'bottom',
                        duration: 2000
                    });
                    return;
                }

                if(this.chkValue[0]==undefined){
                    Toast({
                        message: '请勾选协议',
                        position: 'bottom',
                        duration: 2000
                    });
                    return;
                }

                let roleUrl="/api/common/login/confirm";
                let data={ role:this.radioValue,openId:openId };
                console.log(data);
                this.$post(roleUrl,data)
                    .then(rsp=>{
                        console.log(rsp);
                        if(rsp.code==200 && rsp.success){
                            localStorage.setItem(keys.userType,this.radioValue);
                            window.location.href="/";
                        }else{
                            Toast({
                                message: '出现异常，请联系管理员',
                                position: 'bottom',
                                duration: 2000
                            });
                            return;
                        }
                    })
                    .catch(e=>{
                        console.log('error>>>>>',e);
                    })
            },
            toproxy(){
                window.location.href=keys.webSite+'/doc/index.html';
            },
            weLaunch(e){
                console.log(e);
                this.confirm();
            },
        },
        beforeRouteEnter(to, from, next) {
            // XXX: 修复iOS版微信HTML5 History兼容性问题
            if (to.path != location.pathname) {
                // 此处不可使用location.replace
                location.assign(to.fullPath)
            } else {
                next()
            }
        }
    }
</script>

<style scoped>
    .selectRole{
        width: 750px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .selectRole_title{
        letter-spacing: 4px;
        font-size: 32px;
        color: white;
        position: absolute;
        top:28px;
        text-align: center;
        width: 750px;
    }

    .selectRole_top{
        background-color: #226DF8;
        height: 556px;
        width: 750px;
    }

    .selectRole_points{
        position: absolute;
        right: 0;
        top:156px;
    }

    .selectRole_white{
        background-color: white;
        border-radius: 50px;
        width: 38px;
        height: 126px;
        position: absolute;
        left: 110px;
        top: 276px;
    }

    .selectRole_blue{
        background-color: #226DF8;
        border-radius: 50px;
        width: 38px;
        height: 126px;
        position: absolute;
        left: 148px;
        top: 276px;
        z-index: 1;
    }

    .selectRole_points img{
        width: 510px;
        height: 176px;
    }

    .selectRole_content{
        position: absolute;
        top: 332px;
        width: 690px;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .selectRole_tips{
        margin-top: 166px;
        font-size: 30px;
        color: #202020;
        letter-spacing: 4px;
    }

    .selectRole_radio{
        margin-top: 110px;
        color: #202020;
        letter-spacing: 2px;
    }

    .radioStyle{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .radioStyle label{
        display: none !important;
    }

    .selectRole_btn{
        color: white;
        letter-spacing: 6px;
        font-size: 30px;
        border-radius: 10px;
        background: linear-gradient(180deg, #0093FC, #3705FF);
        width: 476px;
        height: 88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 150px;
    }

    .selectRole_xy {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 24px;
        color: #989898;
        letter-spacing: 2px;
        margin-top: 44px;
    }

    .chkStyle{
        border:0px !important;
    }

    .radioStyle >>> .mint-cell:last-child{
        border:0px !important;
        background-image: unset !important;
    }

    .chkStyle >>> .mint-cell:last-child{
        border:0px !important;
        /*background-color: red;*/
        background-image: unset !important;
    }

    .selectRole_btn_wxapp{
        position: relative;
    }

</style>
